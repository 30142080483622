// Variables
// ---------

$pf-version:          "1.2.5" !default;
$pf-font-name:        'PaymentFont' !default;
$pf-font-file-name:   'paymentfont-webfont' !default;
$pf-font-path:        './fonts' !default;
$pf-font-size-base:   14px !default;
$pf-line-height-base: 1 !default;
$pf-css-prefix:       pf !default;

$pf-var-amazon: "\f000";
$pf-var-american-express: "\f001";
$pf-var-american-express-alt: "\f002";
$pf-var-atm: "\f003";
$pf-var-bankomat: "\f004";
$pf-var-bank-transfer: "\f005";
$pf-var-bitcoin: "\f006";
$pf-var-bitcoin-sign: "\f007";
$pf-var-braintree: "\f008";
$pf-var-btc: "\f009";
$pf-var-card: "\f00a";
$pf-var-carta-si: "\f00b";
$pf-var-cash: "\f00c";
$pf-var-cash-on-delivery: "\f00d";
$pf-var-cb: "\f00e";
$pf-var-cirrus: "\f00f";
$pf-var-cirrus-alt: "\f010";
$pf-var-clickandbuy: "\f011";
$pf-var-credit-card: "\f012";
$pf-var-diners: "\f013";
$pf-var-discover: "\f014";
$pf-var-ec: "\f015";
$pf-var-eps: "\f016";
$pf-var-eur: "\f017";
$pf-var-facture: "\f018";
$pf-var-fattura: "\f019";
$pf-var-flattr: "\f01a";
$pf-var-giropay: "\f01b";
$pf-var-google-wallet: "\f01c";
$pf-var-google-wallet-alt: "\f01d";
$pf-var-gpb: "\f01e";
$pf-var-gratipay: "\f01f";
$pf-var-ideal: "\f020";
$pf-var-ils: "\f021";
$pf-var-inr: "\f022";
$pf-var-invoice: "\f023";
$pf-var-invoice-sign: "\f024";
$pf-var-invoice-sign-alt: "\f025";
$pf-var-invoice-sign-alt-o: "\f026";
$pf-var-invoice-sign-o: "\f027";
$pf-var-jcb: "\f028";
$pf-var-jpy: "\f029";
$pf-var-krw: "\f02a";
$pf-var-maestro: "\f02b";
$pf-var-maestro-alt: "\f02c";
$pf-var-mastercard: "\f02d";
$pf-var-mastercard-alt: "\f02e";
$pf-var-mastercard-securecode: "\f02f";
$pf-var-ogone: "\f030";
$pf-var-paybox: "\f031";
$pf-var-paylife: "\f032";
$pf-var-paypal: "\f033";
$pf-var-paypal-alt: "\f034";
$pf-var-paysafecard: "\f035";
$pf-var-postepay: "\f036";
$pf-var-quick: "\f037";
$pf-var-rechnung: "\f038";
$pf-var-ripple: "\f039";
$pf-var-rub: "\f03a";
$pf-var-skrill: "\f03b";
$pf-var-sofort: "\f03c";
$pf-var-square: "\f03d";
$pf-var-stripe: "\f03e";
$pf-var-truste: "\f03f";
$pf-var-try: "\f040";
$pf-var-unionpay: "\f041";
$pf-var-usd: "\f042";
$pf-var-verified-by-visa: "\f043";
$pf-var-verisign: "\f044";
$pf-var-visa: "\f045";
$pf-var-visa-electron: "\f046";
$pf-var-western-union: "\f047";
$pf-var-western-union-alt: "\f048";
$pf-var-wirecard: "\f049";
$pf-var-sepa: "\f04a";
$pf-var-sepa-alt: "\f04b";
$pf-var-apple-pay: "\f04c";
$pf-var-interac: "\f04d";
$pf-var-paymill: "\f04e";
$pf-var-dankort: "\f04f";
$pf-var-bancontact-mister-cash: "\f050";
$pf-var-moip: "\f051";
$pf-var-pagseguro: "\f052";
$pf-var-cash-on-pickup: "\f053";
$pf-var-sage: "\f054";
$pf-var-elo: "\f055";
$pf-var-elo-alt: "\f056";
$pf-var-payu: "\f057";
$pf-var-mercado-pago: "\f058";
$pf-var-mercado-pago-sign: "\f059";
$pf-var-payshop: "\f05a";
$pf-var-multibanco: "\f05b";
$pf-var-gratipay-sign: "\f05c";
$pf-var-six: "\f05d";
$pf-var-cashcloud: "\f05e";
$pf-var-interac-alt: "\f05f";
$pf-var-klarna: "\f060";
$pf-var-bitpay: "\f061";
$pf-var-venmo: "\f062";
$pf-var-visa-debit: "\f063";
$pf-var-alipay: "\f064";
$pf-var-diners-alt: "\f065";
$pf-var-hipercard: "\f066";
$pf-var-skrill-alt: "\f067";
$pf-var-shopify: "\f068";
$pf-var-direct-debit: "\f069";
$pf-var-sodexo: "\f06a";
$pf-var-bpay: "\f06b";
$pf-var-contactless: "\f06c";
$pf-var-contactless-alt: "\f06d";
$pf-var-eth: "\f06e";
$pf-var-ltc: "\f06f";
$pf-var-visa-pay: "\f070";
$pf-var-wechat-pay: "\f071";
$pf-var-amazon-pay: "\f072";
$pf-var-amazon-pay-alt: "\f073";