// Icon Sizes
// ----------

.#{$pf-css-prefix}-lg {
	font-size: (4em / 3);
	line-height: (3em / 4);
	vertical-align: -15%;
}

.#{$pf-css-prefix}-2x { font-size: 2em; }
.#{$pf-css-prefix}-3x { font-size: 3em; }
.#{$pf-css-prefix}-4x { font-size: 4em; }
.#{$pf-css-prefix}-5x { font-size: 5em; }