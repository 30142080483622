// Icons
// -----

.#{$pf-css-prefix}-amazon {
  &:before {
    content: $pf-var-amazon;
  }
}
.#{$pf-css-prefix}-american-express {
  &:before {
    content: $pf-var-american-express;
  }
}
.#{$pf-css-prefix}-american-express-alt {
  &:before {
    content: $pf-var-american-express-alt;
  }
}
.#{$pf-css-prefix}-atm {
  &:before {
    content: $pf-var-atm;
  }
}
.#{$pf-css-prefix}-bankomat {
  &:before {
    content: $pf-var-bankomat;
  }
}
.#{$pf-css-prefix}-bank-transfer {
  &:before {
    content: $pf-var-bank-transfer;
  }
}
.#{$pf-css-prefix}-bitcoin {
  &:before {
    content: $pf-var-bitcoin;
  }
}
.#{$pf-css-prefix}-bitcoin-sign {
  &:before {
    content: $pf-var-bitcoin-sign;
  }
}
.#{$pf-css-prefix}-braintree {
  &:before {
    content: $pf-var-braintree;
  }
}
.#{$pf-css-prefix}-btc {
  &:before {
    content: $pf-var-btc;
  }
}
.#{$pf-css-prefix}-card {
  &:before {
    content: $pf-var-card;
  }
}
.#{$pf-css-prefix}-carta-si {
  &:before {
    content: $pf-var-carta-si;
  }
}
.#{$pf-css-prefix}-cash {
  &:before {
    content: $pf-var-cash;
  }
}
.#{$pf-css-prefix}-cash-on-delivery {
  &:before {
    content: $pf-var-cash-on-delivery;
  }
}
.#{$pf-css-prefix}-cb {
  &:before {
    content: $pf-var-cb;
  }
}
.#{$pf-css-prefix}-cirrus {
  &:before {
    content: $pf-var-cirrus;
  }
}
.#{$pf-css-prefix}-cirrus-alt {
  &:before {
    content: $pf-var-cirrus-alt;
  }
}
.#{$pf-css-prefix}-clickandbuy {
  &:before {
    content: $pf-var-clickandbuy;
  }
}
.#{$pf-css-prefix}-credit-card {
  &:before {
    content: $pf-var-credit-card;
  }
}
.#{$pf-css-prefix}-diners {
  &:before {
    content: $pf-var-diners;
  }
}
.#{$pf-css-prefix}-discover {
  &:before {
    content: $pf-var-discover;
  }
}
.#{$pf-css-prefix}-ec {
  &:before {
    content: $pf-var-ec;
  }
}
.#{$pf-css-prefix}-eps {
  &:before {
    content: $pf-var-eps;
  }
}
.#{$pf-css-prefix}-eur {
  &:before {
    content: $pf-var-eur;
  }
}
.#{$pf-css-prefix}-facture {
  &:before {
    content: $pf-var-facture;
  }
}
.#{$pf-css-prefix}-fattura {
  &:before {
    content: $pf-var-fattura;
  }
}
.#{$pf-css-prefix}-flattr {
  &:before {
    content: $pf-var-flattr;
  }
}
.#{$pf-css-prefix}-giropay {
  &:before {
    content: $pf-var-giropay;
  }
}
.#{$pf-css-prefix}-google-wallet {
  &:before {
    content: $pf-var-google-wallet;
  }
}
.#{$pf-css-prefix}-google-wallet-alt {
  &:before {
    content: $pf-var-google-wallet-alt;
  }
}
.#{$pf-css-prefix}-gpb {
  &:before {
    content: $pf-var-gpb;
  }
}
.#{$pf-css-prefix}-gratipay {
  &:before {
    content: $pf-var-gratipay;
  }
}
.#{$pf-css-prefix}-ideal {
  &:before {
    content: $pf-var-ideal;
  }
}
.#{$pf-css-prefix}-ils {
  &:before {
    content: $pf-var-ils;
  }
}
.#{$pf-css-prefix}-inr {
  &:before {
    content: $pf-var-inr;
  }
}
.#{$pf-css-prefix}-invoice {
  &:before {
    content: $pf-var-invoice;
  }
}
.#{$pf-css-prefix}-invoice-sign {
  &:before {
    content: $pf-var-invoice-sign;
  }
}
.#{$pf-css-prefix}-invoice-sign-alt {
  &:before {
    content: $pf-var-invoice-sign-alt;
  }
}
.#{$pf-css-prefix}-invoice-sign-alt-o {
  &:before {
    content: $pf-var-invoice-sign-alt-o;
  }
}
.#{$pf-css-prefix}-invoice-sign-o {
  &:before {
    content: $pf-var-invoice-sign-o;
  }
}
.#{$pf-css-prefix}-jcb {
  &:before {
    content: $pf-var-jcb;
  }
}
.#{$pf-css-prefix}-jpy {
  &:before {
    content: $pf-var-jpy;
  }
}
.#{$pf-css-prefix}-krw {
  &:before {
    content: $pf-var-krw;
  }
}
.#{$pf-css-prefix}-maestro {
  &:before {
    content: $pf-var-maestro;
  }
}
.#{$pf-css-prefix}-maestro-alt {
  &:before {
    content: $pf-var-maestro-alt;
  }
}
.#{$pf-css-prefix}-mastercard {
  &:before {
    content: $pf-var-mastercard;
  }
}
.#{$pf-css-prefix}-mastercard-alt {
  &:before {
    content: $pf-var-mastercard-alt;
  }
}
.#{$pf-css-prefix}-mastercard-securecode {
  &:before {
    content: $pf-var-mastercard-securecode;
  }
}
.#{$pf-css-prefix}-ogone {
  &:before {
    content: $pf-var-ogone;
  }
}
.#{$pf-css-prefix}-paybox {
  &:before {
    content: $pf-var-paybox;
  }
}
.#{$pf-css-prefix}-paylife {
  &:before {
    content: $pf-var-paylife;
  }
}
.#{$pf-css-prefix}-paypal {
  &:before {
    content: $pf-var-paypal;
  }
}
.#{$pf-css-prefix}-paypal-alt {
  &:before {
    content: $pf-var-paypal-alt;
  }
}
.#{$pf-css-prefix}-paysafecard {
  &:before {
    content: $pf-var-paysafecard;
  }
}
.#{$pf-css-prefix}-postepay {
  &:before {
    content: $pf-var-postepay;
  }
}
.#{$pf-css-prefix}-quick {
  &:before {
    content: $pf-var-quick;
  }
}
.#{$pf-css-prefix}-rechnung {
  &:before {
    content: $pf-var-rechnung;
  }
}
.#{$pf-css-prefix}-ripple {
  &:before {
    content: $pf-var-ripple;
  }
}
.#{$pf-css-prefix}-rub {
  &:before {
    content: $pf-var-rub;
  }
}
.#{$pf-css-prefix}-skrill {
  &:before {
    content: $pf-var-skrill;
  }
}
.#{$pf-css-prefix}-sofort {
  &:before {
    content: $pf-var-sofort;
  }
}
.#{$pf-css-prefix}-square {
  &:before {
    content: $pf-var-square;
  }
}
.#{$pf-css-prefix}-stripe {
  &:before {
    content: $pf-var-stripe;
  }
}
.#{$pf-css-prefix}-truste {
  &:before {
    content: $pf-var-truste;
  }
}
.#{$pf-css-prefix}-try {
  &:before {
    content: $pf-var-try;
  }
}
.#{$pf-css-prefix}-unionpay {
  &:before {
    content: $pf-var-unionpay;
  }
}
.#{$pf-css-prefix}-usd {
  &:before {
    content: $pf-var-usd;
  }
}
.#{$pf-css-prefix}-verified-by-visa {
  &:before {
    content: $pf-var-verified-by-visa;
  }
}
.#{$pf-css-prefix}-verisign {
  &:before {
    content: $pf-var-verisign;
  }
}
.#{$pf-css-prefix}-visa {
  &:before {
    content: $pf-var-visa;
  }
}
.#{$pf-css-prefix}-visa-electron {
  &:before {
    content: $pf-var-visa-electron;
  }
}
.#{$pf-css-prefix}-western-union {
  &:before {
    content: $pf-var-western-union;
  }
}
.#{$pf-css-prefix}-western-union-alt {
  &:before {
    content: $pf-var-western-union-alt;
  }
}
.#{$pf-css-prefix}-wirecard {
  &:before {
    content: $pf-var-wirecard;
  }
}
.#{$pf-css-prefix}-sepa {
  &:before {
    content: $pf-var-sepa;
  }
}
.#{$pf-css-prefix}-sepa-alt {
  &:before {
    content: $pf-var-sepa-alt;
  }
}
.#{$pf-css-prefix}-apple-pay {
  &:before {
    content: $pf-var-apple-pay;
  }
}
.#{$pf-css-prefix}-interac {
  &:before {
    content: $pf-var-interac;
  }
}
.#{$pf-css-prefix}-paymill {
  &:before {
    content: $pf-var-paymill;
  }
}
.#{$pf-css-prefix}-dankort {
  &:before {
    content: $pf-var-dankort;
  }
}
.#{$pf-css-prefix}-bancontact-mister-cash {
  &:before {
    content: $pf-var-bancontact-mister-cash;
  }
}
.#{$pf-css-prefix}-moip {
  &:before {
    content: $pf-var-moip;
  }
}
.#{$pf-css-prefix}-pagseguro {
  &:before {
    content: $pf-var-pagseguro;
  }
}
.#{$pf-css-prefix}-cash-on-pickup {
  &:before {
    content: $pf-var-cash-on-pickup;
  }
}
.#{$pf-css-prefix}-sage {
  &:before {
    content: $pf-var-sage;
  }
}
.#{$pf-css-prefix}-elo {
  &:before {
    content: $pf-var-elo;
  }
}
.#{$pf-css-prefix}-elo-alt {
  &:before {
    content: $pf-var-elo-alt;
  }
}
.#{$pf-css-prefix}-payu {
  &:before {
    content: $pf-var-payu;
  }
}
.#{$pf-css-prefix}-mercado-pago {
  &:before {
    content: $pf-var-mercado-pago;
  }
}
.#{$pf-css-prefix}-mercado-pago-sign {
  &:before {
    content: $pf-var-mercado-pago-sign;
  }
}
.#{$pf-css-prefix}-payshop {
  &:before {
    content: $pf-var-payshop;
  }
}
.#{$pf-css-prefix}-multibanco {
  &:before {
    content: $pf-var-multibanco;
  }
}
.#{$pf-css-prefix}-gratipay-sign {
  &:before {
    content: $pf-var-gratipay-sign;
  }
}
.#{$pf-css-prefix}-six {
  &:before {
    content: $pf-var-six;
  }
}
.#{$pf-css-prefix}-cashcloud {
  &:before {
    content: $pf-var-cashcloud;
  }
}
.#{$pf-css-prefix}-interac-alt {
  &:before {
    content: $pf-var-interac-alt;
  }
}
.#{$pf-css-prefix}-klarna {
  &:before {
    content: $pf-var-klarna;
  }
}
.#{$pf-css-prefix}-bitpay {
  &:before {
    content: $pf-var-bitpay;
  }
}
.#{$pf-css-prefix}-venmo {
  &:before {
    content: $pf-var-venmo;
  }
}
.#{$pf-css-prefix}-visa-debit {
  &:before {
    content: $pf-var-visa-debit;
  }
}
.#{$pf-css-prefix}-alipay {
  &:before {
    content: $pf-var-alipay;
  }
}
.#{$pf-css-prefix}-diners-alt {
  &:before {
    content: $pf-var-diners-alt;
  }
}
.#{$pf-css-prefix}-hipercard {
  &:before {
    content: $pf-var-hipercard;
  }
}
.#{$pf-css-prefix}-skrill-alt {
  &:before {
    content: $pf-var-skrill-alt;
  }
}
.#{$pf-css-prefix}-shopify {
  &:before {
    content: $pf-var-shopify;
  }
}
.#{$pf-css-prefix}-direct-debit {
  &:before {
    content: $pf-var-direct-debit;
  }
}
.#{$pf-css-prefix}-sodexo {
  &:before {
    content: $pf-var-sodexo;
  }
}
.#{$pf-css-prefix}-bpay {
  &:before {
    content: $pf-var-bpay;
  }
}
.#{$pf-css-prefix}-contactless {
  &:before {
    content: $pf-var-contactless;
  }
}
.#{$pf-css-prefix}-contactless-alt {
  &:before {
    content: $pf-var-contactless-alt;
  }
}
.#{$pf-css-prefix}-eth {
  &:before {
    content: $pf-var-eth;
  }
}
.#{$pf-css-prefix}-ltc {
  &:before {
    content: $pf-var-ltc;
  }
}
.#{$pf-css-prefix}-visa-pay {
  &:before {
    content: $pf-var-visa-pay;
  }
}
.#{$pf-css-prefix}-wechat-pay {
  &:before {
    content: $pf-var-wechat-pay;
  }
}
.#{$pf-css-prefix}-amazon-pay {
  &:before {
    content: $pf-var-amazon-pay;
  }
}
.#{$pf-css-prefix}-amazon-pay-alt {
  &:before {
    content: $pf-var-amazon-pay-alt;
  }
}